import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from '../components/Layout';
import { PageTemplate } from '../templates/Page';

const Page = () => {
  const data = useStaticQuery(graphql`
    query  HomepageQuery {
      markdownRemark(fields: {slug: {eq: "/home/"}}) {
        frontmatter {
          title
          bodyId
        }
        fields {
          slug
        }
        html
      }
    }
  `)

  const { markdownRemark: { frontmatter, html } } = data // data.markdownRemark holds your post data
  const { bodyId } = frontmatter;

  return (
    <Layout bodyId={bodyId}>
      <PageTemplate html={html} />
    </Layout>
  )
}

export default Page;
